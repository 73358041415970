<template>
    <div class="paymet-mode">
        <div class="pay-heading">
            <h3>PAYMENT METHODS</h3>
        </div>
        <div v-if="siteSettings?.business_type==2" class="upi-sec">
            <ul>
                <li><img src="@/assets/images/UPI.png" alt=""></li>
                <li><img src="@/assets/images/GPay.png" alt=""></li>
                <li><img src="@/assets/images/PhonePe.png" alt=""></li>
                <li><img src="@/assets/images/Paytm.png" alt=""></li>
                <li><img src="@/assets/images/BankTranfer.png" alt=""></li>
                <li><img src="@/assets/images/Astropay.png" alt=""></li>
            </ul>
        </div>
        <div v-if="siteSettings?.social_link" class="follow-text-center">
            <p> Follow us on</p>
        </div>
        <div class="socail-icons-list" v-if="siteSettings?.social_link">
            <ul>
                <li v-if="siteSettings.social_link.whatsappno1"><a :href="'//wa.me/'+siteSettings.social_link.whatsappno1" target="_blank"><img src="@/assets/images/facebook.png" alt=""></a></li>
                <li  v-if="siteSettings.social_link.youtube_link"><a :href="siteSettings.social_link.youtube_link" target="_blank"><img src="@/assets/images/twitter.png" alt=""></a></li>
                <li v-if="siteSettings.social_link.telegram_link"><a :href="siteSettings.social_link.telegram_link" target="_blank"><img src="@/assets/images/telegram.png" alt=""></a></li>
                <li v-if="siteSettings.social_link.instagram_link"><a :href="siteSettings.social_link.instagram_link" target="_blank"><img src="@/assets/images/instagram.png" alt=""></a></li>
            </ul>
        </div>
    </div>

    <div class="whats-app">
        <a href="https://api.whatsapp.com/send?phone=**********" target="_blank">
            <img src="@/assets/images/whatsAppicon.svg" alt="">
        </a>
    </div>
</template>
<script>
export default{
    name:'Footer',
    computed: {
			siteSettings() {
				return this.$store.getters.siteSettings;
			},
			domainName() {
				return this.$store.getters.domainName;
			},
			
		},
}
</script>