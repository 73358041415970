<template>
    <div class="mobile-show-footer">
        <ul>
            <li>
                <router-link :to="{name:'inplay'}" class="mobile-view-box">
                    <img src="@/assets/images/play-button.png" alt=""> <br>
                    <span>In-play</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{name:'bonus'}" class="mobile-view-box">
                    <img src="@/assets/images/percentage.webp" alt=""> <br>
                    <span>Bonus</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{name:'Home'}" class="mobile-view-box home">
                    <span><i class="fa-solid fa-house"></i></span>
                </router-link>
            </li>
            <li>
                <a href="https://api.whatsapp.com/send?**********" class="mobile-view-box" target="_blank">
                    <img src="@/assets/images/WhatsApp.webp" alt=""> <br>
                    <span>Helpline</span>
                </a>
            </li>
            <li>
                <a :class="{ 'disabled-link': !checkIsLogin() }" href="#acc-menu-btn123" data-bs-toggle="offcanvas"
                    role="button" aria-controls="acc-menu-btn123" class="mobile-view-box">
                    <img src="@/assets/images/user.png" alt=""> <br>
                    <span>Account</span>
                </a>
            </li>
        </ul>


    </div>

</template>
<script>

import Account from '@/modules/account/components/Account.vue';

export default {
    name: 'MobileFooter',
    methods: {
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
    },
    components: {
        Account
    }
}
</script>

<style scoped>
.disabled-link {
    pointer-events: none;
}
</style>