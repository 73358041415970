<template>
    <section class="banner-section">
        <div class="slider-img">
            <Carousel class="owl-carousel owl-theme" :autoplay="2000" :wrapAround="true" :items-to-show="1" v-if="siteSettings && sitePosters">
                <Slide v-for="(poster, pKey) in sitePosters" :key="pKey">
                    <div class="item">
                        <div class="slider-img">
                            <figure>
                                <img :src="siteSettings.aws_url + siteSettings.storage_path.poster_path + poster.image" :alt="poster.image">
                            </figure>
                        </div>
                    </div>
                </Slide>
            </Carousel>
        </div>
    </section>
</template>
<script>

import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
    name: 'Banner',
    components: {
        Carousel,
        Slide,
        Pagination
    },
    data() {
        return {
            breakpoints: {
                0: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
                375: {
                    itemsToShow: 1.25,
                    snapAlign: 'start',
                },
                480: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                668: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                }
            },
            siteSettings: this.$store?.getters?.siteSettings
        }
    },
    computed: {
        sitePosters() {
            if(this.siteSettings && this.siteSettings.poster && this.siteSettings.poster.length) {
                return this.siteSettings.poster.filter(s => s.poster_type == 1);
            } else {
                return [];
            }
        }
    },
}
</script>