const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue'); 
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
const Statements = () => import(/* webpackChunkName: "statements" */ './views/Statements.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');

const BetsRoutes =   {
    path: '/',
    component: BetsModule,
    children: [
      {
        path: 'bets',
        name: 'bets',
        component: Bets
      },
      {
        path: 'statements',
        name: 'statements',
        component: Statements
      },
      {
        path: 'profit-loss',
        name: 'profit-loss',
        component: ProfitLoss
      },
    ],
  }

export default BetsRoutes;