import { createApp } from 'vue'
import App from './App.vue'

import router from "./router"
import store from "./store";
import VueCookies from 'vue3-cookies';

//import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
//import "./assets/css/bootstrap.min.css.map";
import "./assets/css/style.css";
import "./assets/css/style1.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css";
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import "vue-gtranslate/translator.css"
import { VueReCaptcha } from 'vue-recaptcha-v3'
import './registerServiceWorker'

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_SITEKEY,loaderOptions: {autoHideBadge: true} })
app.use(VueCookies);
app.mount("#app");
