<template>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample123" aria-labelledby="offcanvasExampleLabel"
        style="visibility: visible;" aria-modal="true" role="dialog">
        <div class="offcanvas-body">
            <div class="sidebar-details">
                <div class="sidebar-menu left-open-menu">
                    <div class="open-side-menu-sec">
                        <ul>
                            <li data-bs-dismiss="offcanvas">
                                <router-link to="/">
                                    <img src="@/assets/images/home2.png" alt="">
                                    <span>Home</span>   
                                </router-link>
                            </li>
                            <li>
                                <a href="#install-apk-model" data-bs-toggle="modal">
                                    <img src="@/assets/images/Download.png" alt="">
                                    <span>Download App Now</span>
                                </a>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                                <router-link to="/casino">
                                    <img src="@/assets/images/inr.png" alt="">
                                    <span> Play LIVE Casino</span>
                                </router-link>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                                <a href="#">
                                    <img src="@/assets/images/Star.png" alt="">
                                    <span> Bonus & More</span>
                                </a>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                                <router-link to="/sponsors">
                                    <img src="@/assets/images/thumb.png" alt="">
                                    <span> Our Sponsorships</span>
                                </router-link>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                                <router-link to="/faq">
                                    <img src="@/assets/images/FAQ.png" alt="">
                                    <span>FAQs</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name:'MobileSideBar',
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
}
</script>