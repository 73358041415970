
const state = {
    siteSettings: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    favouriteGames: null,
    globalLoader: false,
    captchaData: null,
};
const getters = {
    siteSettings: state => state?.siteSettings,
    gamesData: state => state?.gamesData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    favouriteGames: state => state?.favouriteGames,
    globalLoader: state => state?.globalLoader,
    captchaData: state => state?.captchaData,
};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    }
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};