<template>
    <div class="app-text-detail-page">
        <div class="title-wrapper">
            <span>Help</span>
        </div>
        <div class="rule-content-menu-sec">
            <router-link to="/help/RESPONSIBLE_GAMING">
                <div class="menu-item-left">
                    <p>Responsible Gaming</p>
                </div>
                <div class="menu-item-right">
                    <i class="fa-solid fa-caret-right"></i>
                </div>
            </router-link>
        </div>
        <div class="rule-content-menu-sec">
            <router-link to="/help/TERM_CONDITIONS">
                <div class="menu-item-left">
                    <p>Terms and Conditions</p>
                </div>
                <div class="menu-item-right">
                    <i class="fa-solid fa-caret-right"></i>
                </div>
            </router-link>
        </div>
    </div>
    <div class="app-text-detail-page">
        <div class="title-wrapper">
            <span>Information</span>
        </div>
        <div class="rule-content-menu-sec">
            <router-link to="/information/BONUS_POLICY">
                <div class="menu-item-left">
                    <p>Bonus Policy</p>
                </div>
                <div class="menu-item-right">
                    <i class="fa-solid fa-caret-right"></i>
                </div>
            </router-link>
        </div>
        <div class="rule-content-menu-sec">
            <router-link to="/information/PRIVACY_POLICY">
                <div class="menu-item-left">
                    <p>KYC and Privacy Policy</p>
                </div>
                <div class="menu-item-right">
                    <i class="fa-solid fa-caret-right"></i>
                </div>
            </router-link>
        </div>
        <div class="rule-content-menu-sec">
            <router-link to="/information/AML_POLICY">
                <div class="menu-item-left">
                    <p>AML Policy</p>
                </div>
                <div class="menu-item-right">
                    <i class="fa-solid fa-caret-right"></i>
                </div>
            </router-link>
        </div>
        <div class="rule-content-menu-sec">
            <router-link to="/information/RULES">
                <div class="menu-item-left">
                    <p>Rules</p>
                </div>
                <div class="menu-item-right">
                    <i class="fa-solid fa-caret-right"></i>
                </div>
            </router-link>

        </div>
    </div>

    <div class="app-text-detail-page">
        <div class="title-wrapper">
            <span>About</span>
        </div>
        <div class="rule-content-menu-sec">
            <router-link to="/about/ABOUT_US">
                <div class="menu-item-left">
                    <p>About Us</p>
                </div>
                <div class="menu-item-right">
                    <i class="fa-solid fa-caret-right"></i>
                </div>
            </router-link>
        </div>
        <div class="rule-content-menu-sec">
            <router-link to="/about/PROMOTIONS">
                <div class="menu-item-left">
                    <p>Promotions</p>
                </div>
                <div class="menu-item-right">
                    <i class="fa-solid fa-caret-right"></i>
                </div>
            </router-link>
        </div>
        <div class="rule-content-menu-sec">
            <router-link to="/about/CONTACT_US">
                <div class="menu-item-left">
                    <p>Contact Us</p>
                </div>
                <div class="menu-item-right">
                    <i class="fa-solid fa-caret-right"></i>
                </div>
            </router-link>
        </div>
    </div>

    <div class="menu-footer-container">
        <p>This website is operated by Never Ending Gaming N.V.duly incorporated under the laws of CURACAO, company
            registration number 154043, whose address is at Abraham de Veerstraat 9, Curacao,</p>
    </div>
</template>
<script>
export default {
    name: 'SubFooter'
}
</script>