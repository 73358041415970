import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const Faq = () => import("@/shared/components/side-menu/Faq.vue");
const Sponsors = () => import("@/shared/components/side-menu/Sponsors.vue");
import AuthorizationRoutes from '@/modules/authorization';
import SportsRoutes from "@/modules/sports";
import BonusRoutes from "@/modules/bonus";
import ProfileRoutes from "@/modules/profile";
import BetsRoutes from "@/modules/bets";
import StaticRoutes from "@/modules/static";
import WalletRoutes from "@/modules/wallet";
import RaceRoutes from "@/modules/race";
import GamesRoutes from "@/modules/games";
import MatkaRoutes from "@/modules/matka";
import ReferEarnRoutes from "@/modules/refer-earn";

const routes = [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/faq",
      name: "faq",
      component: Faq
    },
    {
      path: "/sponsors",
      name: "sponsors",
      component: Sponsors
    },
    AuthorizationRoutes,
    SportsRoutes,
    BonusRoutes,
    ProfileRoutes,
    BetsRoutes,
    StaticRoutes,
    WalletRoutes,
    RaceRoutes,
    GamesRoutes,
    MatkaRoutes,
    ReferEarnRoutes
  ];
  
  
  export default createRouter({
    history: createWebHistory(),
    routes
  });