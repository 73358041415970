<template>
    <section v-if="checkForBack()" class="header-wrapper">
        <div class="container">
            <div class="header-sec">
                <div class="header-nav">
                    <div @click="$router.back()" class="back-arrow">
                        <a><img src="@/assets/images/back-arrow.svg" alt=""></a>
                    </div>
                    <div class="header-logo">
                        <div class="list-con header-sub-con">
                            <h4 class="text-light">{{ getTitle() }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div v-else class="all-game-tab-btn">
        <ul class="nav nav-pills">

            <li class="nav-item" role="presentation" @click="currentTab = 'all'">
                <router-link to="/" class="nav-link" :class="{'active': currentTab == 'all' }">
                    <span class="tab-icons"><img src="@/assets/images/home2.png" alt=""></span>
                    <span class="tab-text">All</span>
                </router-link>
            </li>

            <li class="nav-item" role="presentation" v-for="(sport,n_index) in non_custom_sports" :key="n_index" @click="currentTab = sport.id">
                <router-link :to="'/sports/' + sport.slug + '/' + sport.id" class="nav-link" :class="{'active': currentTab == sport.id }">
                    <span class="tab-icons">
                        <img v-if="sport.id == 4" src="@/assets/images/4.webp" alt="">
                        <img v-if="sport.id == 1" src="@/assets/images/1.webp" alt="">
                        <img v-if="sport.id == 2" src="@/assets/images/2.webp" alt="">
                    </span>
                    <span class="tab-text">{{ sport.name }}</span>
                </router-link>
            </li>

            <li class="nav-item" role="presentation">
                <router-link to="/casino" class="nav-link" @click="selected = 'CASINO'"
                    :class="selected == 'CASINO' ? 'active' : ''">
                    <span class="tab-icons"><img src="@/assets/images/66102.webp" alt=""></span>
                    <span class="tab-text">casino</span>
                </router-link>
            </li>

            <li class="nav-item" role="presentation">
                <router-link @click="selected = 'HORSE_RACE'" :class="selected == 'HORSE_RACE' ? 'active' : ''"
                    :to="{ name: 'racing-category', params: { type: 'HORSE_RACE' } }" class="nav-link">
                    <span class="tab-icons"><img src="@/assets/images/7.webp" alt=""></span>
                    <span class="tab-text">Horse Racing</span>
                </router-link>
            </li>

            <li class="nav-item" role="presentation">
                <router-link @click="selected = 'GREY_HOUND'" class="nav-link" :class="selected == 'GREY_HOUND' ? 'active' : ''"
                    :to="{ name: 'racing-category', params: { type: 'GREY_HOUND' } }">
                    <span class="tab-icons"><img src="@/assets/images/menu-4339.png" alt=""></span>
                    <span class="tab-text"> Greyhound Racing </span>
                </router-link>
            </li>

            <li class="nav-item" role="presentation">
                <router-link to="/matka" @click="selected = 'MATKA'" :class="selected == 'MATKA' ? 'active' : ''" class="nav-link">
                    <span class="tab-icons"><img src="@/assets/images/menu-2378961.png" alt=""></span>
                    <span class="tab-text"> Matka </span>
                </router-link>
            </li>

            <li class="nav-item" role="presentation">
                <a @click="selected = 'VIRTUAL_SPORTS'"
                    :class="selected == 'VIRTUAL_SPORTS' ? 'active' : ''" class="nav-link">
                    <span class="tab-icons"><img src="@/assets/images/66101.webp" alt=""></span>
                    <span class="tab-text">virtual Sports</span>
                </a>
            </li>

            <li class="nav-item" role="presentation" v-for="(c_sport,c_index) in custom_sports" :key="c_index" @click="currentTab = c_sport.id">
                <router-link :to="'/sports/' + c_sport.name + '/' + c_sport.id" class="nav-link" :class="{'active': currentTab == c_sport.id }">
                    <span class="tab-icons"><img :src="c_sport.sport_icon" alt=""></span>
                    <span class="tab-text">{{ c_sport.name }}</span>
                </router-link>
            </li>

        </ul>
    </div>
</template>
<script>
import OddApi from '../../services/click_api';
import * as apiName from '../../services/urls';
export default {
    name: 'NavBar',
    data(){
        return{            
            non_custom_sports:[],
            custom_sports:[],
            currentTab: 'all',
            routeNamesBack: ['wallet', 'wallet-history', 'wallet-history-detail', 'bonus', 'refer-earn','wallet-deposit','wallet-withdraw','deposit-payment-method'],
            selected: 'ALL'
        }
    },
    created() {
        this.get_sportsList()
    },
    methods: {
        get_sportsList() {
            this.loading = true;
            OddApi.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports = []
                        for (var i = 0; i < all_sports.length; i++) {
                            if (all_sports[i].is_custom == 0) {
                                this.non_custom_sports.push(all_sports[i])
                            } else {
                                this.custom_sports.push(all_sports[i])
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list = {}
                        sport_list['custom'] = this.custom_sports
                        sport_list['non_custom'] = this.non_custom_sports
                        localStorage.setItem('sports_List', JSON.stringify(sport_list))
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                    // this.showErrorModalFunc(error.data.message);
                }
            });

        },
        checkForBack() {
            let routeName = this.$route.name;
            if (this.routeNamesBack.includes(routeName)) {
                return true;
            }
            else {
                return false
            }
        },
        getTitle() {
            let routeName = this.$route.name;
            if (routeName == 'sports') {
                let routeType = this.$route.params.type;
                switch (routeType) {
                    case 'cricket':
                        return 'Cricket';
                    case 'soccer':
                        return 'Soccer';
                    case 'tennis':
                        return 'Tennis';
                    case 'ASSEMBLY_ELECTION':
                        return 'Assembly Election';
                    case 'IPL_WINNER':
                        return 'IPL Winner';
                    case 'HORSE_RACE':
                        return 'Horse Race'
                    case 'GREY_HOUND':
                        return 'Grey Hound'
                    default:
                        return 'Sports';
                }
            }
            else if (routeName == 'games') {
                let routeType = this.$route.params.type;
                switch (routeType) {
                    case 'LIVE_CASINO':
                        return 'Live Casino';
                    case 'WORLD_CASINO':
                        return 'World Casino';
                }
            }

            else if (routeName == 'bonus') {
                return 'Bonus';
            }
            else if (routeName == 'refer-earn') {
                return 'Refer and Earn';
            }
            else if (routeName == 'virtual-sport') {
                return 'Virtual Sports';
            }
            else if (routeName == 'sports-book') {
                return 'Sportsbook';
            }
            else if (routeName == 'matka') {
                return 'Matka';
            }
            else if (routeName == 'notifications') {
                return 'Notifications';
            }
            else if (routeName == 'favourites') {
                return 'Favourites';
            }
            else if (routeName == 'wallet') {
                return 'Wallet';
            }
            else if (routeName == 'wallet-deposit') {
                return 'Wallet Deposit';
            }
            else if (routeName == 'deposit-payment-method') {
                return 'Deposit Payment Method';
            }
           
            else if (routeName == 'wallet-history') {
                return 'Wallet History'
            }
            else if (routeName == 'wallet-history-detail') {
                return 'Transaction Detail'
            }
            else if (routeName == 'payment-method' || routeName == 'wallet-withdraw') {
                return 'Withdraw Details'
            }
            else if (routeName == 'add-account') {
                return 'Account Details'
            } else if (routeName == 'CricketFight') {
                return 'CRICKET FIGHT'
            }
            else if (routeName == 'MatchDetails' || routeName == 'PlaceBet') {
                return this.cricketFight_eventName
            }
            else if (routeName == 'MyContests') {
                return 'MY CONTESTS'
            }
            else if (routeName == 'MyContestBetDetails') {
                return this.myContest_eventName
            }
            else if (routeName == 'racing-category' || routeName == 'racing-list-details' || routeName == 'racing-carousel') {
                let routeType = this.$route.params.type;
                switch (routeType) {
                    case 'HORSE_RACE':
                        return 'Horse Race';
                    case 'GREY_HOUND':
                        return 'Grey Hound';
                    default:
                        return 'Racing';
                }
            }

        },
    }

}

</script>