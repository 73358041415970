const ProfileModule = () => import(/* webpackChunkName: "profile-module" */ './views/Module.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ './views/Profile.vue');
const ChangePassword = () => import(/* webpackChunkName: "change-password" */ './views/ChangePassword.vue');
const Setting = () => import(/* webpackChunkName: "setting" */ './views/Setting.vue');
const PasswordHistory = () => import(/* webpackChunkName: "setting" */ './views/PasswordHistory.vue');

const ProfileRoutes = {
    path: '/',
    component: ProfileModule,
    children: [
        {
            path: 'profile',
            name: 'profile',
            component: Profile
        },
        {
            path: 'change-password',
            name: 'change-password',
            component: ChangePassword
        },
        {
            path: 'setting',
            name: 'setting',
            component: Setting
        },
        {
            path: 'password-history',
            name: 'password-history',
            component: PasswordHistory
        },
    ],
}   

export default ProfileRoutes;