<template>
    <div class="modal fade game-point-note-modal" id="language_selection_pop_up" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border: none;background: transparent;">
                <div class="modal-body">
                    <section class="sub-header-wrapper">
                        <div class="gp-content">
                            <img data-bs-dismiss="modal" class="gp-closeButton" src="@/assets/images/closeButton.svg"
                                alt="">
                            <div id="app_1"> </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name: 'LanguageSelection',

}
</script>