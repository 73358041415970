<template>
    <div class="logo-sign-up-sec">
        <div class="logo-l-img">
            <div>
                <a data-bs-toggle="offcanvas" href="#offcanvasExample123" role="button" aria-controls="offcanvasExample123"
                    class="logo-mobile-img">
                    <i class="fa-solid fa-bars"></i>
                </a>
            </div>
            <div v-if="siteSettings()">
                <router-link to="/" class="logo-desktop">
                    <img :src="siteSettings().aws_url + siteSettings().storage_path.domain_image + siteSettings().logo" alt="">
                    <!-- <img src="@/assets/images/NewLogo1.png" alt=""> -->
                </router-link>
            </div>
        </div>
        <div v-if="!checkIsLogin()" class="sign-r-box">
            <ul>
                <li class="input-search">
                    <input type="text" class="form-control" placeholder="Search...">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </li>
                <li class="login-btn">
                    <router-link :to="{ name: 'login' }" class="cmn-btn123">Log in</router-link>
                </li>
                <li class="sign-up-btn">
                    <router-link :to="{ name: 'sign-up' }" class="cmn-btn123">Sign up</router-link>
                </li>
            </ul>
        </div>

        <div v-if="checkIsLogin()" class="sign-r-box">
            <ul>
                <li v-if="siteSettings()?.business_type == 2" class="sign-up-btn">
                    <a  @click.prevent="getWalletGetway()" class="cmn-btn123">Deposit</a>
                    <div  v-if="userdataFunc()" class="balance-exp-text">BAL : {{ userdataFunc().balance }}</div>
                </li>
                <li v-if="siteSettings()?.business_type == 2" class="login-btn">
                    <a @click.prevent="getWalletGetway()" class="cmn-btn123">withdraw</a>
                    <div v-if="userdataFunc()" class="balance-exp-text">EXP :{{ userdataFunc().exposure }}</div>
                </li>
                <li class="user-menu-d-sec">
                    <a href="#acc-menu-btn123" data-bs-toggle="offcanvas" role="button" aria-controls="acc-menu-btn123"
                        class="mobile-view-box">
                        <img src="@/assets/images/user.png" alt="">
                        <span>Account</span>
                    </a>
                </li>
            </ul>
        </div>

    </div>

</template>
<script>
import Account from '@/modules/account/components/Account.vue';
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";

export default {
    name: 'Header',
    data() {
        return {
            userData: null,
        };
    },
    created() {
        this.userData = this.$store.getters.stateUser;
    },
    methods: {
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        userdataFunc() {
            return this.$store.getters.stateUser;
        },
         siteSettings() {
         return this.$store.getters.siteSettings
      },
      getWalletGetway() {
            getWalletGetwayServiceCall();
        }
    },
    components: { Account }
}
</script>