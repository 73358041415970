<template>
  <div class="open-mobile-menu-sec">
    <MobileSideBar />
  </div>

  <header class="header-wapper" loading="lazy">
    <Header />
  </header>

  <Banner v-if="$route.name == 'Home'" />

  <section class="all-sports-tab-btn">


    <NavBar v-if="excludedNavbarRoutes.includes($route.name)" />

    <router-view @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

  </section>

  <footer class="payment-mode-sec">
    <Footer />
  </footer>

  <sub-footer class="app-footer-content-sec">
    <SubFooter />
  </sub-footer>


  <div class="mobile-view-footer-sec">
    <MobileFooter />
  </div>


  <div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
    ref="successmodal" id="successfullyModal">
    <SuccessModal :message="successMsg" />
  </div>

  <div :class="{ show: showErrorModal }" style="z-index: 9999999999999999;" class="successfully-wrapper"
    id="wrongtoaster">
    <ErrorModal :message="errorMsg" />
  </div>


  <GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
  <GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

  <Account v-if="checkIsLogin()" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

  <Offers />
  <LanguageSelection @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
  <DownloadApkModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
</template>
<script>

import Header from '@/shared/components/header/Header.vue';
import Footer from '@/shared/components/footer/Footer.vue';
import MobileFooter from '@/shared/components/footer/MobileFooter.vue';
import SubFooter from '@/shared/components/footer/SubFooter.vue';
import MobileSideBar from '@/shared/components/side-menu/MobileSideBar.vue';
import { AUTH_TOKEN } from './shared/constants/cookies-const';
import SuccessModal from './shared/components/modal/SuccessModal.vue';
import ErrorModal from './shared/components/modal/ErrorModal.vue';
import { setHeaders } from './shared/services/headers';
import axios from 'axios';

import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import Banner from './shared/components/banner/Banner.vue';
import NavBar from './shared/components/header/NavBar.vue';
import Account from '@/modules/account/components/Account.vue';
import GamesPop1 from './shared/components/modal/GamesPop1.vue';
import GamesPop2 from './shared/components/modal/GamesPop2.vue';
import LanguageSelection from './shared/components/header/LanguageSelection.vue';
import socketService from "./socket/sport-socket-service";
import Offers from "./shared/components/header/Offers.vue";
import { Modal } from 'bootstrap';
import { useReCaptcha } from 'vue-recaptcha-v3'
import { provide } from 'vue';
import DownloadApkModal from './shared/components/modal/DownloadApkModal.vue';

export default {
  name: 'App',
  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
      loading: false,

      showSuccessModal: false,
      successMsg: "",
      showErrorModal: false,
      errorMsg: "",
      excludedNavbarRoutes: [
        'Home',
        'sports',
      ],
      offerModal: '',
    }
  },
  setup() {
		const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

		const recaptcha = async (type) => {
			await recaptchaLoaded();
			const token = await executeRecaptcha(type);
			return token;
		};

		provide('recaptcha', recaptcha);

		return {};
	},
  components: {
    Header,
    Footer,
    MobileFooter,
    SubFooter,
    MobileSideBar,
    SuccessModal,
    ErrorModal,
    Banner,
    NavBar,
    Account,
    GamesPop1,
    GamesPop2,
    LanguageSelection,
    Offers,
    DownloadApkModal
  },
  created() {
    this.getSiteSettingsServiceCall();

  },
  mounted() {
    //for use on js pages
    window.store = this.$store;
    window.cookies = this.$cookies;
    window.router = this.$router;
    this.offerModal = new Modal("#offer_pop_up");
    window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
    //for add on all APIs
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
    setHeaders();
    
    //checking internet connection
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.socketAllEvents();
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    checkIsLogin() {
      return this.$store.getters.isAuthenticated;
    },
    getSiteSettingsServiceCall() {
      this.loading = true;
      api.get(apiName.SITE_SETTINGS + "?domain=" + window.location.hostname).then(response => {
        console.log("Result Site setting: ", response);
        this.loading = false;
        if (response) {
          if (response.status == 200) {
            console.log("Site setting", response);
            if (response?.data?.status == 0) {
              this.showErrorModalFunc(response.data.debug[0]);
            }
            else {
              //this.showSuccessModalFunc(response.data.message);
              console.log("Site Settings : ", response?.data?.data);
              let resData = response?.data?.data
              let is_offer = localStorage.getItem("is_offer");
              this.$store.dispatch('setSettings', resData);
              this.setManifest(resData);

              // Create FAVICON
              const favElement = document.createElement('link');
              favElement.rel = 'icon';
              favElement.href = resData.aws_url + resData.storage_path.domain_image + resData.fav_logo;
              document.head.insertAdjacentElement('beforeend', favElement);
              
              const title = document.getElementById("title");
              title.text = resData.domain_name;

              if (this.checkIsLogin() && resData.offer != null && is_offer == null) {
                this.offerModal.show();
              }
            }
          }
        }
      }).catch(error => {
        this.loading = false;
        if (error) {
          console.log("Error Login : ", error[0]);
          this.showErrorModalFunc(error[0]);
        }
      });
    },
    setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
    getWalletBalance() {
      this.loading = true;
      let authToken = this.$cookies.get(AUTH_TOKEN);
      if (!authToken) return;
      let headers = {
        'Authorization': `Bearer ${authToken}`
      }
      api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
        this.loading = false;
        if (response && response.status == 200 && response.data) {
          let user = this.$store.getters.stateUser;
          user.balance = response.data.bl;
          user.exposure = response.data.ex;
          user.bets_count = response.data.bc;
          this.$store.dispatch('setUser', user);
          if ((moment.unix(user.exp).diff(moment(), 'seconds') / 60) < 5 && !this.refreshInit) {
            this.refreshAuthToken(headers);
          }
          setTimeout(function () {
            this.getWalletBalance();
          }.bind(this), 2000);
        } else {
          this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
          this.$store.dispatch('resetAllState');
        }
      }).catch(error => {
        this.loading = false;
        if (error) {
          this.showErrorModalFunc(error[0]);
        }
      });
    },
    refreshAuthToken(headers) {
      this.refreshInit = true;
      api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
        let user = this.$store.getters.stateUser;
        user.token = response.data.data.token;
        user.exp = response.data.data.exp;
        this.$cookies.set(AUTH_TOKEN, response.data.data.token);
        this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
        //for add on all APIs
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
        this.$store.dispatch('setUser', user);
        this.refreshInit = false;
      }).catch(error => {
        if (error) {
          this.showErrorModalFunc(error[0]);
        }
        this.refreshInit = false;
      });
    },
    showErrorModalFunc(message) {
      this.errorMsg = message;
      this.showErrorModal = true;
      setTimeout(() => {
        this.showErrorModal = false;
      }, 2000);
      window.scrollTo(0, 0);
    },
    showSuccessModalFunc(message) {
      this.successMsg = message;
      this.showSuccessModal = true;
      setTimeout(() => {
        this.showSuccessModal = false;
      }, 2000);
      window.scrollTo(0, 0);
    },
    socketAllEvents() {
      if (this.$store.getters.stateUser) {
        socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
        socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
        socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

        socketService.on("DepositRequest", (data) => {
          if (data.type == "myId") {
            if (data.notify_type == 1) {
              this.$store.dispatch('setNotification');
              this.showSuccessModalFunc('New Notification...')
            }
          }
        });
        socketService.on("logout", (data) => {
          if (data.type == "logout") {
            // logout user from users
            // if (data.notify_type == 1) {
            // 	this.$store.dispatch('setNotification');
            // 	this.showSuccessModalFunc('New Notification...')
            // }
          }
        });
        socketService.on("banned", (data) => {
          if (data.type == "match_ban") {
            // match ban condition
          } else if (data.type == 'league_ban') {
            // league ban condition
          }
        });

      }
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    }
  }
}
</script>
