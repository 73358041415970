const StaticModule = () => import(/* webpackChunkName: "static-module" */ './views/Module.vue');
const About = () => import(/* webpackChunkName: "bets" */ './views/About.vue');
const Help = () => import(/* webpackChunkName: "win-loss" */ './views/Help.vue');
const Information = () => import(/* webpackChunkName: "open-bets" */ './views/Information.vue');


const StaticRoutes = {
    path: '/',
    component: StaticModule,
    children: [
        {
            path: 'about/:type',
            name: 'about',
            component: About,
        },

        {
            path: 'help/:type',
            name: 'help',
            component: Help,
        },
        {
            path: 'information/:type',
            name: 'information',
            component: Information,
        }
    ],
}

export default StaticRoutes;