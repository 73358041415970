<template>
    <div class="acc-menu-rgt-bar">
        <div class="open-mobile-menu-sec">
            <div class="offcanvas offcanvas-end" tabindex="-1" id="acc-menu-btn123" aria-labelledby="offcanvasExampleLabel" style="visibility: visible;" aria-modal="true" role="dialog">
                <div v-if="userData" class="offcanvas-body">
                    <div class="abcd-heading-sec">
                        <h3>{{ userData?.userid }}</h3>
                       
                    </div>
                    <div v-if="userData" class="balance-info-sec">
                        <div class="balance-heading">
                            <h4> Balance Information </h4>
                            <div style="visibility: hidden;" ref="close_canvas" data-bs-dismiss="offcanvas" >close</div>
                        </div>
                        <div class="available-credit-box">
                            <span>Balance:</span>
                            <span>{{ userData?.balance }}</span>
                        </div>
                        <div class="available-credit-box">
                            <span>Net Exposure:</span>
                            <span>{{ userData?.exposure }}</span>
                        </div>
                        <div class="available-credit-box">
                            <span>Available Credit:</span>
                            <span>{{ userData?.available_credits }}</span>
                        </div>
                    </div>
                    <div v-if="siteSettings().business_type == 2" class="right-side-menu-deposit-withdrol-btn">
                        <div class="rgt-side-deposit-btn">
                            <a @click="getWalletGetway()"
                                class="cmn-d-w-btn"> Deposit </a>
                        </div>
                        <div class="rgt-side-withdrol-btn">
                            <a @click="getWalletGetway()"
                                class="cmn-d-w-btn"> Withdrawal </a>
                        </div>
                    </div>
                    <div class="sidebar-details">
                        <div class="sidebar-menu left-open-menu">
                            <div class="open-side-menu-sec">
                                <ul>
                                    <li>
                                        <router-link @click="hideThisCanvas()" :to="{ name: 'profile' }">
                                            <img src="@/assets/images/Profile.png" alt="">
                                            <span> My profile</span>
                                        </router-link>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="modal" data-bs-target="#language_selection_pop_up">
                                            <img src="@/assets/images/language-list-icon.svg" alt="">
                                            <span style="color: white;"> Languages</span>
                                        </a>
                                    </li>

                                    <li>
                                        <router-link @click="hideThisCanvas()"  :to="{ name: 'change-password' }">
                                            <img src="@/assets/images/EditPassword.png" alt="">
                                            <span> Edit password</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link @click="hideThisCanvas()" :to="{ name: 'statements' }">
                                            <img src="@/assets/images/account.png" alt="">
                                            <span> Account statement</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <a @click="getWalletGetway()" class="text-white">
                                            <img src="@/assets/images/wallet-icon.svg" alt="">
                                            <span>wallet</span>
                                        </a>
                                    </li>
                                    <li>
                                        <router-link @click="hideThisCanvas()" to="/bonus">
                                            <img src="@/assets/images/bonus-icon.svg" alt="">
                                            <span>bonus</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link @click="hideThisCanvas()" to="/refer-earn">
                                            <img src="@/assets/images/gift-icon.svg" alt="">
                                            <span>Refer & Earn</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link @click="hideThisCanvas()" :to="{ name: 'profit-loss' }">
                                            <img src="@/assets/images/Profit.png" alt="">
                                            <span> Profit/Loss</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link @click="hideThisCanvas()" :to="{ name: 'bets' }">
                                            <img src="@/assets/images/betHistory.png" alt="">
                                            <span> Bets History</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link @click="hideThisCanvas()" :to="{ name: 'password-history' }">
                                            <img src="@/assets/images/passwordHistory.png" alt="">
                                            <span>Password History</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link @click="hideThisCanvas()" :to="{ name: 'setting' }">
                                            <img src="@/assets/images/Setting.png" alt="">
                                            <span>Setting</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="log-out-cmn-btn123">
                        <a data-bs-toggle="modal" data-bs-target="#logoutmodal"><i class="fa-solid fa-power-off"></i><span>Logout</span></a>
                    </div>
                </div>
            </div>
        </div>
        <LogoutModal/>
    </div>
</template>
<script>

import LogoutModal from './LogoutModal.vue'
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";

export default {
    name: 'Account',
    components:{
        LogoutModal
    },
    data() {
        return {
            visibility:'visible',
            userData: null,
        }
    },
    mounted() {
        this.userData = this.$store?.getters?.stateUser;
    },
    methods: {
        siteSettings() {
            return this.$store?.getters?.siteSettings
        },
        hideThisCanvas() {
            let myOffcanvas = this.$refs.close_canvas;
            // let bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
            // bsOffcanvas.hide();
            myOffcanvas.click();
        },
        getWalletGetway() {
            getWalletGetwayServiceCall();
        }
    }
}
</script>