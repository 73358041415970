const state = {
    user: null,
    chips:null,
    token: null,
};
const getters = {
    isAuthenticated: state => !!state?.user,    
    stateUser: state => state?.user,
    chips: state => state?.chips,
    token: state => state?.token
};
const actions = {
    setUser ({ commit},user) {
        commit('setUser',user);
    },
    setChips({ commit }, chips) {
        commit('setChips', chips);
    },

    setToken({ commit }, token) {
        commit('setToken', token);
    },
};
const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setChips(state, chips) {
        state.chips = chips
    },

    setToken(state, token) {
        state.token = token;
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};