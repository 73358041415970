import axios from 'axios'
import { headers } from '@/shared/services/headers'
import { TIME_5000 } from '@/shared/constants/services-const'
import handlerErrorFromApis from '@/shared/services/error-handler';

const instance = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API,
  timeout: TIME_5000,
  headers: {Authorization:window.authorization_token}
})

instance.interceptors.response.use(function (response) {
  return Promise.resolve(response);
}, function (error) {
  if(error)
  {
    console.log("Error : ",error);
    return handlerErrorFromApis(error)
  }
});

export default instance;